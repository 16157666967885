$(function () {

    $('#sortable').sortable({
        update: function (event, ui) {
            var route = $(this).data('route');

            var data = $(this).sortable('serialize');
            var csrf_token = $('meta[name="csrf-token"]').attr('content');

            $.ajax({
                headers: {
                    'X-CSRF-TOKEN': csrf_token
                },
                data: data,
                type: 'POST',
                url: route,
                success: function (data) {

                },
                error: function (data) {
                    //alert('oups') ;
                }
            });
        }
    });
    

    $('.sortable').sortable({
        
        update: function (event, ui) {
            var route = $(this).data('route');

            var data = $(this).sortable('serialize');
            var csrf_token = $('meta[name="csrf-token"]').attr('content');

            $.ajax({
                headers: {
                    'X-CSRF-TOKEN': csrf_token
                },
                data: data,
                type: 'POST',
                url: route,
                success: function (data) {

                },
                error: function (data) {
                    //alert('oups') ;
                }
            });
        }
    });

});
