$(function () {

    $('.tableDefault').DataTable({

        "responsive": true,
        'paging': true,
        'lengthChange': true,
        'searching': true,
        'ordering': false,
        'info': true,
        'autoWidth': true,
        "language": {
            'url': '//cdn.datatables.net/plug-ins/1.10.10/i18n/French.json'
        },
        "columnDefs": [
            { "width": "80px", "targets": [-1] },
            // { "width": "80px", "targets": [-2] },
            // { "width": "140px", "targets": [-3] }
        ]
    })

    $('.tableDashboard').DataTable({

        "responsive": true,
        'paging': false,
        'lengthChange': true,
        'searching': true,
        'ordering': false,
        'info': true,
        'autoWidth': true,
        "language": {
            'url': '//cdn.datatables.net/plug-ins/1.10.10/i18n/French.json'
        },
        "columnDefs": [
            { "width": "80px", "targets": [-1] },
            { "width": "120px", "targets": [-2] },
            { "width": "120px", "targets": [-3] }
        ]
    })

    $('.tablePictures').DataTable({

        "responsive": true,
        'paging': false,
        'lengthChange': true,
        'searching': true,
        'ordering': false,
        'info': true,
        'autoWidth': true,
        "language": {
            'url': '//cdn.datatables.net/plug-ins/1.10.10/i18n/French.json'
        },
        "columnDefs": [
            { "width": "80px", "targets": [-1] },
            { "width": "80px", "targets": [-2] },
            // { "width": "140px", "targets": [-3] }
        ]
    })
    
    $('.tableSlide').DataTable({

        "responsive": true,
        'paging': true,
        'lengthChange': true,
        'searching': true,
        'ordering': false,
        'info': true,
        'autoWidth': true,
        "language": {
            'url': '//cdn.datatables.net/plug-ins/1.10.10/i18n/French.json'
        },
        "columnDefs": [
            { "width": "80px", "targets": [-1] },
            { "width": "40px", "targets": [-2] },
            // { "width": "140px", "targets": [-3] }
        ]
    })

    $('.tableActuality').DataTable({

        "responsive": true,
        'paging': true,
        'lengthChange': false,
        'searching': true,
        'ordering': false,
        'info': false,
        'autoWidth': true,
        "language": {
            'url': '//cdn.datatables.net/plug-ins/1.10.10/i18n/French.json'
        },
        "columnDefs": [
            { "width": "80px", "targets": [-1] },
            { "width": "40px", "targets": [-2] },
            { "width": "120px", "targets": [-3] },
            { "width": "120px", "targets": [-4] },
        ]
       
    })

    $('.tableActualityArchives').DataTable({

        "responsive": true,
        'paging': false,
        'lengthChange': false,
        'searching': true,
        'ordering': false,
        'info': false,
        'autoWidth': true,
        "language": {
            'url': '//cdn.datatables.net/plug-ins/1.10.10/i18n/French.json'
        },
        "columnDefs": [
            { "width": "80px", "targets": [-1] },
            { "width": "120px", "targets": [-2] },
        ]
       
    })

    $('.tableTeams').DataTable({

        "responsive": true,
        'paging': false,
        'lengthChange': false,
        'searching': true,
        'ordering': false,
        'info': false,
        'autoWidth': true,
        "language": {
            'url': '//cdn.datatables.net/plug-ins/1.10.10/i18n/French.json'
        },
        "columnDefs": [
            { "width": "80px", "targets": [-1] },
        ]
       
    })

    $('.tablePages').DataTable({

        "responsive": true,
        'paging': false,
        'lengthChange': true,
        'searching': true,
        'ordering': false,
        'info': true,
        'autoWidth': true,
        "language": {
            'url': '//cdn.datatables.net/plug-ins/1.10.10/i18n/French.json'
        },
        "columnDefs": [
            { "width": "40px", "targets": [0] },
        ]
       
    })


    $('.tableActualities').DataTable({

        "responsive": true,
        'paging': true,
        'lengthChange': true,
        'searching': true,
        'ordering': true,
        'info': true,
        'autoWidth': true,
        "language": {
            'url': '//cdn.datatables.net/plug-ins/1.10.10/i18n/French.json'
        },
        // "order" :[[2,'asc']],
        "columnDefs": [
            { "width": "80px", "targets": [-1] },
            { "width": "80px", "targets": [-2] },
            { "width": "140px", "targets": [-3] }
        ]
    })


})
