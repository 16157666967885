

try {
    window.Popper = require('popper.js').default;
    window.$ = window.jQuery = require('jquery');

    require('jquery-ui-sortable');

    require('bootstrap');
    require('overlayscrollbars');
    require('../../public/vendor/adminlte/dist/js/adminlte'); // working
    // require('../../vendor/almasaeed2010/adminlte/dist/js/adminlte.min.js');
    
    require('../../public/vendor/bootstrap-switch/js/bootstrap-switch.min.js'); // working
 
    require('datatables.net');
    require('datatables.net-bs4');
    require('datatables.net-responsive');
    require('datatables.net-responsive-bs4');

    require('./datatables');
    require('./reorder');
    // require('./splide');
    

    $(".alert-success").delay(3000).slideUp(300, function() {
    });


} catch (e) { }
